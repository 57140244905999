@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
header {
	border-bottom: 1px solid #ebeae8;
}

.header-main {
	.header-body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1215px;
		width: 96%;
		margin: 18px auto 15px;

		img.logo {
			width: 220px;
			height: 55px;
			@include ch-break-down("lg") {
				width: 143px;
				height: 36px;
			}
		}
		@include ch-break-down("lg") {
			margin: 8px auto 10px;
		}
	}
	.header-form-wrapper {
		display: flex;
		align-items: center;
		margin-top: 10px;
		gap: 10px;
		position: relative;

		@include ch-break-down("lg") {
			margin-top: 0;
		}
		.mobile-search-icon {
			border: none;
			input {
				margin-right: -5px;
			}
			@media (min-width: 601px) {
				display: none !important;
			}
			@media (max-width: 600px) {
				order: 2;
			}
		}
		// This button lives in MobileNavbar but acts like it is in this Header component
		.mobile-nav-toggle {
			border: none;
			background-color: white;
			order: 3;
			&:hover {
				cursor: pointer;
			}
		}
	}
}

// search bar ----------------------------

.visually-hidden {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.search-header {
	background-color: #e9e9e9;
	padding: 20px;
}

.search-page-bar {
	background: #fff;

	input[type="search"] {
		width: 100%;
	}

	::-webkit-input-placeholder {
		/* WebKit browsers */
		color: transparent;
	}
	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: transparent;
	}
	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: transparent;
	}
	:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: transparent;
	}
	input::placeholder {
		color: transparent;
	}
}

.search-categories {
	display: flex;
	margin: 15px 0 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	overflow-x: auto;
	.search-category {
		-webkit-box-flex: 1;
		flex-grow: 1;
		text-align: center;
		margin: 0 10px;
		white-space: nowrap;
		cursor: pointer;
		line-height: 2;
		&.active {
			color: $ch-secondary-purple;
			border-bottom: 3px solid $ch-secondary-purple;
		}
	}
}

.header-search-bar {
	input[type="search"] {
		position: relative;
		padding-left: 16px;
		width: 184px;
		-webkit-transition: width 0.4s;
		transition: width 0.4s;
		height: 23px;
		&:focus {
			width: 253px;
			border-radius: 5px;
			height: 34px;
			outline: none;
			box-shadow: 0 0 0 5px white, 0 0 0 8px $ch-secondary-purple;

			@include ch-break-down("lg") {
				box-shadow: 0 0 0 3px $ch-secondary-purple;
			}
		}
	}
	.suggestion-wrapper {
		padding-top: 8px;
		top: 44px;
		@include ch-break-down("lg") {
			top: 30px;
		}
	}
}

.desktop-search {
	@media (max-width: 600px) {
		display: none !important;
	}
}

.search-bar {
	position: relative;
	display: block;
	padding: 5px 3.45px 5px 0;
	width: auto;
	border-radius: 8px;
	border: 1px solid #969696;
	height: 47px;

	@include ch-break-down("lg") {
		padding: 2px 5px 2px 0;
		height: 35px;
	}

	&.expanded {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	form {
		display: flex;
		justify-content: center;
		height: 100%;
	}
	.input-wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		background-color: white;
		border-radius: 8px;
	}
	input[type="search"] {
		position: relative;
		display: inline-block;
		border-radius: 2px;
		outline-color: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		border: none;
		font-size: 14px;
		color: rgb(16,25,33);
		margin: 0 10px 0 5px;
		font-weight: 500;

		@include ch-break-down("lg") {
			margin: 0 5px 0 0;
		}

		/* Hide the clear button (x) in the search input */
		&::-webkit-search-cancel-button {
    		-webkit-appearance: none;
    		appearance: none;
		}
	}
	.search-submit {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='%236629b7' width='30' height='30'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'%3E%3C/path%3E%3C/svg%3E");
		width: 27px;
		height: 27px;
		margin-top: 0;
		margin-right: 4px;
		border-radius: 6px;
		background-size: 70%;
		background-color: transparent;
		position: relative;
		vertical-align: bottom;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		border: 0;
		cursor: pointer;
		color: transparent;
	}
}

.suggestion-wrapper {
	position: absolute;
	z-index: 100;
	top: 37px;
	border: 1px solid #969696;
	right: -1px;
	left: -1px;
	border-top-color: #ebeae8;
	border-radius: 0 0 8px 8px;
	color: rgb(16, 25, 33);

	font-family: var(--ch-ff-headline);
	font-weight: ch-fw("primitive_medium");
	padding: 5px 8px 10px 8px;
	background-color: var(--ch-c-neutral-surface-highest);
	line-height: 21px;
	box-shadow:
		0px 2px 3px 0px hsla(27, 60%, 3%, 0.12),
		0px 7px 11px 0px hsla(27, 60%, 3%, 0.12),
		0px 15px 25px 0px hsla(27, 60%, 3%, 0.12);
	font-size: 14px;

	.angle-right {
		padding-left: 1rem;
		-webkit-transform-origin: center;
		transform-origin: center;
		-webkit-transition: -webkit-transform 0.2s;
		transition: -webkit-transform 0.2s;
		transition:
			transform 0.2s,
			-webkit-transform 0.2s;
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='%23444444' d='M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z'%3E%3C/path%3E%3C/svg%3E");
		width: 1rem;
		height: 1rem;
		background-repeat: no-repeat;
		background-position: 100%;
	}

	.is-label {
		border-bottom: 1px solid var(--ch-c-neutral-divider-solid-variant);
		line-height: 1.5rem;
		margin: 15px 12px 10px 12px;
		pointer-events: none;
		cursor: default;
		text-decoration: none;
		color: var(--ch-c-neutral-on-surface-strong);
		font-size: 11px;
		padding: 0;
	}

	.suggestion {
		.suggestion-search-link,
		.suggestion-page-link {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px 12px;

			.suggestion-text {
				color: rgb(16,25,33);
				border-top: 0;
				margin: 0;

				i {
					margin-right: 8px;
				}

				b {
					--primary-surface-base: rgba(243, 230, 254, 1);
					--primary-on-surface: #{ch-c("primary_accent")};

					padding: 5px 0;
					border-radius: 6px;
					background: var(--primary-surface-base, rgb(243, 230, 254));
					color: var(--primary-on-surface, rgb(91, 41, 157));
					font-weight: 600;
				}
			}
			@media (hover: hover) {
				&:hover {
					background-color: #b6a89b29;
					border-radius: 6px;
					cursor: pointer;
					span {
						text-decoration: none;
						color: #101921;
					}

					b {
						background: rgba(173, 114, 253, 0.16);
					}
				}
			}
			&:focus {
				background: var(
					--neutral-state-on-surface-transparent-pressed,
					#b6a89b3d
				);
				span {
					text-decoration: none;
					color: #101921;
				}
			}
			&:active {
				background: rgba(182, 168, 155, 0.24);
				b {
					background: rgba(173, 114, 253, 0.24);
				}
			}
		}
	}
}

