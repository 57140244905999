@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.language-switcher {
	display: none;
	margin-top: 0;
	border: 1px solid #969696;
	height: 46px;
	border-radius: 8px;
	width: 165px;
	padding: 4px 10px;
	color: #969696;
	font-size: 14px;
	background-color: #fff;
	position: relative;
	z-index: 1;
	&.active {
		display: flex;
		align-items: center;
		position: absolute;
		right: 0;
		top: 45px;
	}
	form {
		line-height: 22px;
		position: relative;
		input[type="submit"] {
			position: absolute;
			z-index: 1;
			//visibility: visible;
			top: 30px;
			right: -5px;
			width: 140px;
			text-align: center;
			color: $ch-secondary-purple;
			border: 1px solid $ch-secondary-purple;
			border-radius: 4px;
			background-color: #fff;
			padding: 6px 2px 5px;
			font-weight: bold;
			line-height: 1.2;
		}
		&::after {
			content: " ";
			display: inline-block;
			height: 0;
			width: 0;
			border: 7px solid #fff;
			border-top-color: $ch-secondary-purple;
			position: absolute;
			top: 8px;
			right: 2px;
			z-index: 0;
		}
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
			background-color: transparent;
			color: #444;
			width: 90px;
			position: relative;
			z-index: 1;
			font-size: 14px;
			font-weight: 500;
		}
	}
}

.mobile-language-toggle {
	border: none;
	background-color: transparent;
	height: 100%;
	@media (max-width: 600px) {
		position: relative;
		left: 10px;
		.svg-globe-icon {
			padding: 0;
		}
	}
}

.svg-globe-icon {
	width: 21px;
	height: 21px;
	display: inline-block;
	float: left;
	padding: 0 10px;
	box-sizing: content-box;
}

.no-translation-message {
	display: inline-block;
	max-width: 75%;
	line-height: 1.3;
	margin-left: 10px;
}

.svg-globe-icon.not-translated {
	padding: 0;
}

@media (min-width: 1225px) {
	.language-switcher {
		display: flex;
		align-items: center;
	}

	.mobile-language-toggle {
		display: none;
	}
}
