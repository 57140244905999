@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
nav.desktop {
	position: relative;

	a {
		color: $brand-text-color;
		font-size: 15px;
		text-decoration: none;
		&:hover {
			color: $ch-secondary-purple;
		}
	}

	ul {
		width: 100%;
		list-style-type: none;
	}

	ul.level-1 {
		max-width: 1215px;
		width: 96%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;

		li:hover:not(:first-child) .sub-nav-container {
			visibility: visible;
			max-height: 600px;
			opacity: 1;
			-webkit-transition:
				opacity 250ms ease 250ms,
				max-height 500ms ease,
				visibility 0s linear 0s,
				top 0s linear 0s;
			transition:
				opacity 250ms ease 50ms,
				max-height 500ms ease,
				visibility 0s linear 0s;
		}
	}

	//lines up "Home" tab up with the logo
	& > ul > li:first-child {
		padding-left: 0;
		margin-left: -7px;
	}

	a.level-1-link {
		color: $brand-ve-strong;
		padding: 11px 7px;
		border-bottom: 4px solid transparent;
		font-size: 14px;
		display: inline-block;
		white-space: nowrap;

		&:hover, &[aria-expanded="true"] {
			color: $ch-secondary-purple;
			text-decoration: none;
			border-bottom: 4px solid $ch-secondary-purple;
			outline-color: $ch-secondary-purple;
        }
	}

	.sub-nav-container {
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		height: auto;
		width: 100%;
		z-index: 1001;
		background-color: $ch-corporate-white;
		border-top: 1px solid $brand-grey-light;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
		padding: 30px 0;
		visibility: hidden;
		max-height: 0;
		overflow: hidden;
		list-style: none;
		-webkit-transition:
			opacity 250ms ease 250ms,
			max-height 500ms ease,
			visibility 0s linear 500ms,
			top 0s linear 500ms;
		transition:
			opacity 250ms ease 250ms,
			max-height 500ms ease,
			visibility 0s linear 500ms,
			top 0s linear 500ms;

		.sub-nav-content {
			display: flex;
			width: 96%;
			margin: 0 auto;
			max-width: 1215px;
			line-height: 16px;

			.level-2-link,
			.level-3-link {
				color: $brand-ve-strong;
				font-size: 14px;
				text-decoration: none;
				line-height: 1.4;
				padding-bottom: 12px;
				&:hover {
					color: $ch-secondary-purple;
				}
			}
			.level-3-link {
				color: #5B5146;
			}
			.level-2-link {
				font-weight: 700;
			}

			ul {
				flex-direction: column;
				justify-content: initial;
				padding-left: 0;
				margin-left: 0;

				&.level-3 {
					padding-top: 12px;
				}

				li {
					padding-bottom: 12px;
					padding-right: 16px;

					&.level-3-parent,
					&.level-3-parent + li {
						margin-top: 25px;
					}

					&.level-3-parent:first-child {
						margin-top: 0;
					}
				}

				li.nav-image {
					line-height: 1em;
					b {
						color: $ch-secondary-purple;
					}
					img {
						border-radius: 24px;
					}
					p {
						padding-top: 8px;
						font-size: 12px;
						strong {
							color: $ch-secondary-purple;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1225px) {
	nav.desktop {
		display: none;
	}
}
