@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.scroll-wrapper {
	overflow-y: scroll;
	overflow-x: hidden;
	position: absolute;
	top: 67px;
	bottom: 0;
	right: 0;
	width: 100%;
}

html:has(.mobile-navbar.open) {
	overflow: hidden;
	.scroll-wrapper {
		width: 357px;
	}
}

.off-canvas-menu {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	transition:
		opacity 0.4s,
		width 0s 0.4s;

	&.active {
		width: 100%;
		opacity: 1;
		transition: opacity 0.4s;
	}
}

.mobile-navbar {
	position: fixed;
	top: 0;
	right: -100%;
	height: 100%;
	width: 100%;
	max-width: 357px;
	overflow: hidden;
	font-size: 16px;
	background: #ffffff;
	box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
	z-index: 1000;
	transition: right 0.3s ease-in-out;

	&.open {
		right: 0;
	}

	.header-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 20px 15px 15px;
		border-bottom: 1px solid #ebeae8;
		position: relative;
		z-index: 3;
	}

	.mobile-header-search {
		display: flex;
		flex-direction: column;
		background-color: $brand-legacy-purple;
		padding-top: 20px;
		.search-bar.header-search-bar {
			display: block;
			height: 100%;
			form {
				flex-direction: column;
			}
			&.expanded {
				.input-wrapper {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
		.suggestion-wrapper {
			position: relative;
			top: 0;
			right: 0;
			left: 0;
			width: 307px;
		}

		.mobile-searchbar-wrapper {
			margin: 0 20px 20px;

			.input-wrapper {
				height: 40px;
			}
			.search-bar {
				border: none;
			}

			.search-submit {
				margin-left: auto;
			}

			input[type="search"] {
				width: 100%;
				max-width: 250px;
				margin: 0 10px;
				height: 23px;

				&:focus {
					box-shadow: 0 0 0 5px white, 0 0 0 7px $ch-secondary-purple;
				}
			}
		}

		.action-buttons {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			padding: 0 25px 10px 28px;
			background-color: transparent;
			list-style: none;

			li {
				padding: 6px 0 10px;
			}

			li a {
				display: flex;
				flex-direction: column;
				align-items: center;
				color: #fff;
				font-size: 11px;
				text-decoration: none;
				white-space: nowrap;
				letter-spacing: normal;
				line-height: 1;

				img {
					margin-bottom: 8px;
					filter: invert(1);
				}
			}
		}
	}
}

.level-1-list {
	transition: transform 0.4s;
	margin-bottom: 50px;

	button,
	a {
		display: block;
		width: 100%;
		padding: 19px 18px 19px 13px;
		margin: 5px;
		border-bottom: 1px solid #ebeae8;
		background: none;
		text-align: left;
		color: rgb(16, 25, 33);
		font-weight: 700;
		text-decoration: none;
		box-sizing: border-box;
		line-height: 19.2px;
		cursor: pointer;
		font-size: 14px;

		span {
			float: right;
			font-weight: normal;
			font-size: 24px;
		}

		&[aria-expanded="true"] span {
			transform: scaleX(1.6);
		}
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-top: 0;
		width: 100%;

		li {
			width: 100%;
		}
	}

	.level-1-item,
	.nav-item,
	.level-2-item {
		width: 330px;
	}

	.level-1-item[aria-expanded="true"] + .level-2-list {
		max-height: 1100px;
		visibility: visible;
	}

	.level-2-list {
		height: auto;
		max-height: 0;
		overflow: hidden;
		visibility: hidden;
		transition: max-height 0.4s ease-in-out;
		a,
		.level-2-item {
			padding-left: 36px;
			font-weight: 500;
			color: rgb(91, 81, 70);

			img {
				float: right;
			}
		}
	}

	.level-2-item[aria-expanded="false"] + .level-3-list {
		overflow: hidden;
		visibility: hidden;
	}

	.level-3-list {
		position: absolute;
		top: 0;
		left: 340px;
		width: 340px;
		height: 0;

		button {
			font-weight: normal;
		}

		.level-3-item {
			display: flex;
			align-items: center;

			img {
				margin-right: 10px;
			}
		}
	}
}

.level-1-list:has(.level-2-item[aria-expanded="true"]) {
	transform: translateX(-340px);
}

@media (min-width: 1225px) {
	.navigation-mobile {
		display: none;
	}

	.mobile-nav-toggle {
		display: none;
	}
}
.mobile-searchbar-wrapper .suggestion-wrapper {
	box-shadow: none;
	border: none;
}
