@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.accordion-item {
	padding-left: 0;
	overflow: visible;
	padding-bottom: 15px;
	border-bottom: 1px solid #ebeae8;
	&:hover {
		cursor: pointer;
	}
	&:hover button {
		border-color: #9c8dc3;
		color: #9c8dc3;
		transform: scale(1.1);
	}
	button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: block;
		float: right;
		border: 1px solid #6629b7;
		color: #6629b7;
		background-color: #fff;
		border-radius: 50%;
		width: 49px;
		height: 49px;
		position: relative;
		top: -10px;
		font-size: 24px;
		line-height: 47px;
		padding: 0 8px;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
		text-align: center;
		&:hover {
			cursor: pointer;
		}
	}
	h3 {
		color: #444;
		font-size: 18px;
		line-height: 1.6;
		font-weight: bold;
		padding-right: 5%;
		margin-bottom: 26px;
		margin-top: 30px;
		width: calc(100% - 40px);
	}
}

.accordion-menu {
	background-color: white;
	overflow: auto;
	overflow-y: hidden;
	text-align: left;
	transition: max-height 0.3s ease-in-out;
	width: calc(100% - 40px);
	&.active {
		max-height: 100vh;
		overflow-y: hidden;
	}
}
