// =============================================================================
// __MIXINS.SCSS
// -----------------------------------------------------------------------------
// Site styles.
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. @use
//   02. Breakpoints
//   03. Background Tokens
//   04. <Txt> Tokens / Variants
//   05. Inline Text Link
//   06. User Select
//   07. Masks
//   08. <ScrollerX> Styled Scrollbar
// =============================================================================

// @use
// =============================================================================

@use "sass:map";

// Breakpoints
// =============================================================================
// 01. Media of at least the minimum breakpoint width. No query for the
//     smallest breakpoint. Makes the @content apply to the given breakpoint
//     and wider.
// 02. Media of at most the maximum breakpoint width. No query for the largest
//     breakpoint. Makes the @content apply to the given breakpoint and
//     narrower.
// 03. Media that spans multiple breakpoint widths. Makes the @content apply
//     between the min and max breakpoints
// 04. Media between the breakpoint's minimum and maximum widths. No minimum
//     for the smallest breakpoint, and no maximum for the largest one. Makes
//     the @content apply only to the given breakpoint, not viewports any wider
//     or narrower.
// 05. Media that supports a hover style. @content applies to all breakpoints.
// 06. Media that prefers reduced motion. @content applies to all breakpoints.
// 07. Media that does not prefer reduced motion. @content applies to all
//     breakpoints.

@mixin ch-break-up($slug, $bp: $ch_breakpoints_map) {
	// 01
	$min: ch-break-min($slug, $bp);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin ch-break-down($slug, $bp: $ch_breakpoints_map) {
	// 02
	$max: ch-break-max($slug, $bp);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin ch-break-range($lower, $upper, $bp: $ch_breakpoints_map) {
	// 03
	$min: ch-break-min($lower, $bp);
	$max: ch-break-max($upper, $bp);
	@media (min-width: $min) and (max-width: $max) {
		@content;
	}
}

@mixin ch-break-only($slug, $bp: $ch_breakpoints_map) {
	// 04
	$min: ch-break-min($slug, $bp);
	$max: ch-break-max($slug, $bp);
	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include ch-break-up($slug) {
			@content;
		}
	} @else if $min == null {
		@include ch-break-down($slug) {
			@content;
		}
	}
}

@mixin ch-break-hover() {
	// 05
	@media (hover) {
		@content;
	}
}

@mixin ch-break-prm() {
	// 06
	@media (prefers-reduced-motion: reduce) {
		@content;
	}
}

@mixin ch-break-not-prm() {
	// 07
	@media (prefers-reduced-motion: no-preference) {
		@content;
	}
}

@mixin ch-break-portrait() {
	// 08
	@media (orientation: portrait) {
		@content;
	}
}

@mixin ch-break-landscape() {
	// 08
	@media (orientation: landscape) {
		@content;
	}
}

// Background Tokens
// =============================================================================

@mixin ch-bg-base-tokens($slug: "card", $extends: ()) {
	$defaults: (
		"attachment": scroll,
		"blend-mode": normal,
		"color": #{ch-hsla("neutral_0")},
		"image": none,
		"position": 50% 50%,
		"repeat": no-repeat,
		"size": cover,
	);

	$defaults: map.merge($defaults, $extends);

	@each $property, $value in $defaults {
		@if ($value != null) {
			background-#{$property}: var(
				--ch-#{$slug}-bg-#{$property},
				#{$value}
			);
		}
	}
}

@mixin ch-bg-hover-tokens($slug: "card") {
	background-color: var(
		--ch-#{$slug}-bg-color-hover,
		var(--#{$slug}-bg-color)
	);
}

@mixin ch-bg-active-tokens($slug: "card") {
	background-color: var(
		--ch-#{$slug}-bg-color-active,
		var(--#{$slug}-bg-color)
	);
}

// <Txt> Tokens / Variants
// =============================================================================

@mixin ch-txt-variant($variant: "body") {
	$txt_overflow: var(
		--ch-txt-#{$variant}-overflow,
		var(--ch-txt-overflow, visible)
	);
	$txt_overflow_wrap: var(
		--ch-txt-#{$variant}-overflow-wrap,
		var(--ch-txt-overflow-wrap, break-word)
	);
	$txt_display: var(
		--ch-txt-#{$variant}-display,
		var(--ch-txt-display, block)
	);
	$txt_flex_grow: var(
		--ch-txt-#{$variant}-flex-grow,
		var(--ch-txt-flex-grow, 0)
	);
	$txt_flex_shrink: var(
		--ch-txt-#{$variant}-flex-shrink,
		var(--ch-txt-flex-shrink, 1)
	);
	$txt_flex_basis: var(
		--ch-txt-#{$variant}-flex-basis,
		var(--ch-txt-flex-basis, auto)
	);
	$txt_width: var(--ch-txt-#{$variant}-width, var(--ch-txt-width, auto));
	$txt_min_width: var(
		--ch-txt-#{$variant}-min-width,
		var(--ch-txt-min-width, 0px)
	);
	$txt_max_width: var(
		--ch-txt-#{$variant}-max-width,
		var(--ch-txt-max-width, 100%)
	);

	$txt_margin_x_start: var(
		--ch-txt-#{$variant}-margin-x-start,
		var(
			--ch-txt-#{$variant}-margin-x,
			var(--ch-txt-margin-x-start, var(--ch-txt-margin-x, 0px))
		)
	);
	$txt_margin_x_end: var(
		--ch-txt-#{$variant}-margin-x-end,
		var(
			--ch-txt-#{$variant}-margin-x,
			var(--ch-txt-margin-x-end, var(--ch-txt-margin-x, 0px))
		)
	);
	$txt_margin_y_start: var(
		--ch-txt-#{$variant}-margin-y-start,
		var(
			--ch-txt-#{$variant}-margin-y,
			var(--ch-txt-margin-y-start, var(--ch-txt-margin-y, 0px))
		)
	);
	$txt_margin_y_end: var(
		--ch-txt-#{$variant}-margin-y-end,
		var(
			--ch-txt-#{$variant}-margin-y,
			var(--ch-txt-margin-y-end, var(--ch-txt-margin-y, 0px))
		)
	);

	$txt_font_family: var(
		--ch-txt-#{$variant}-font-family,
		var(--ch-txt-font-family, #{ch-ff($variant)})
	);
	$txt_font_size: var(
		--ch-txt-#{$variant}-font-size,
		var(--ch-txt-font-size, #{ch-fs("#{$variant}_0")})
	);
	$txt_font_style: var(
		--ch-txt-#{$variant}-font-style,
		var(--ch-txt-font-style, normal)
	);
	$txt_font_weight: var(
		--ch-txt-#{$variant}-font-weight,
		var(--ch-txt-font-weight, #{ch-fw("#{$variant}_normal")})
	);
	$txt_line_height: var(
		--ch-txt-#{$variant}-line-height,
		var(--ch-txt-line-height, #{ch-lh("#{$variant}")})
	);
	$txt_letter_spacing: var(
		--ch-txt-#{$variant}-letter-spacing,
		var(--ch-txt-letter-spacing, #{ch-ls("#{$variant}")})
	);
	$txt_text_align: var(
		--ch-txt-#{$variant}-text-align,
		var(--ch-txt-text-align, inherit)
	);
	$txt_text_decoration_line: var(
		--ch-txt-#{$variant}-text-decoration-line,
		var(--ch-txt-text-decoration-line, none)
	);
	$txt_text_decoration_thickness: var(
		--ch-txt-#{$variant}-text-decoration-thickness,
		var(--ch-txt-text-decoration-thickness, auto)
	);
	$txt_text_decoration_style: var(
		--ch-txt-#{$variant}-text-decoration-style,
		var(--ch-txt-text-decoration-style, solid)
	);
	$txt_text_underline_offset: var(
		--ch-txt-#{$variant}-text-underline-offset,
		var(--ch-txt-text-underline-offset, 0.095em)
	);
	$txt_text_underline_position: var(
		--ch-txt-#{$variant}-text-underline-position,
		var(--ch-txt-text-underline-position, auto)
	);
	$txt_text_indent: var(
		--ch-txt-#{$variant}-text-indent,
		var(--ch-txt-text-indent, 0px)
	);
	$txt_text_overflow: var(
		--ch-txt-#{$variant}-text-overflow,
		var(--ch-txt-text-overflow, clip)
	);
	$txt_text_shadow: var(
		--ch-txt-#{$variant}-text-shadow,
		var(--ch-txt-text-shadow, none)
	);
	$txt_text_transform: var(
		--ch-txt-#{$variant}-text-transform,
		var(--ch-txt-text-transform, #{ch-tt($variant)})
	);
	$txt_white_space: var(
		--ch-txt-#{$variant}-white-space,
		var(--ch-txt-white-space, normal)
	);
	$txt_word_break: var(
		--ch-txt-#{$variant}-word-break,
		var(--ch-txt-word-break, normal)
	);
	$txt_word_spacing: var(
		--ch-txt-#{$variant}-word-spacing,
		var(--ch-txt-word-spacing, normal)
	);
	$txt_filter: var(--ch-txt-#{$variant}-filter, var(--ch-txt-filter, none));
	$txt_mix_blend_mode: var(
		--ch-txt-#{$variant}-mix-blend-mode,
		var(--ch-txt-mix-blend-mode, none)
	);
	$txt_transition_duration: var(
		--ch-txt-#{$variant}-transition-duration,
		var(--ch-txt-transition-duration, #{ch-dur("0")})
	);
	$txt_transition_property: var(
		--ch-txt-#{$variant}-transition-property,
		var(--ch-txt-transition-property, color, text-decoration, text-shadow)
	);
	$txt_transition_timing_function: var(
		--ch-txt-#{$variant}-transition-timing-function,
		var(--ch-txt-transition-timing-function, #{ch-ease("std")})
	);

	$txt_bold_font_weight: var(
		--ch-txt-#{$variant}-font-weight-bold,
		var(--ch-txt-font-weight-bold, #{ch-fw("#{$variant}_bold")})
	);

	$txt_line_clamp: var(
		--ch-txt-#{$variant}-line-clamp,
		var(--ch-txt-line-clamp, 2)
	);
	$txt_line_clip_padding_y_end: var(
		--ch-txt-#{$variant}-line-clip-padding-y-end,
		var(--ch-txt-line-clip-padding-y-end, #{ch-unit-scale(1em, -12)})
	);

	$txt_color: var(
		--ch-txt-#{$variant}-color,
		var(--ch-txt-color, #{ch-c("neutral_on_surface_strong")})
	);

	$txt_color_hover: var(
		--ch-txt-#{$variant}-color-hover,
		var(--ch-txt-color-hover, #{$txt_color})
	);
	$txt_color_active: var(
		--ch-txt-#{$variant}-color-active,
		var(--ch-txt-color-active, #{$txt_color})
	);

	$txt_text_decoration_color: var(
		--ch-txt-#{$variant}-text-decoration-color,
		var(--ch-txt-text-decoration-color, transparent)
	);
	$txt_text_decoration_color_hover: var(
		--ch-txt-#{$variant}-text-decoration-color-hover,
		var(--ch-txt-text-decoration-color, #{$txt_text_decoration_color})
	);
	$txt_text_decoration_color_active: var(
		--ch-txt-#{$variant}-text-decoration-color-active,
		var(--ch-txt-text-decoration-color, #{$txt_text_decoration_color})
	);

	:where(&) {
		overflow: $txt_overflow;
		overflow-wrap: $txt_overflow_wrap;
		display: $txt_display;
		flex-grow: $txt_flex_grow;
		flex-shrink: $txt_flex_shrink;
		flex-basis: $txt_flex_basis;
		width: $txt_width;
		min-width: $txt_min_width;
		max-width: $txt_max_width;
		margin-inline: $txt_margin_x_start $txt_margin_x_end;
		margin-block: $txt_margin_y_start $txt_margin_y_end;
		font-family: $txt_font_family;
		font-size: $txt_font_size;
		font-style: $txt_font_style;
		font-weight: $txt_font_weight;
		line-height: $txt_line_height;
		letter-spacing: $txt_letter_spacing;
		text-align: $txt_text_align;
		text-decoration-color: $txt_text_decoration_color;
		text-decoration-line: $txt_text_decoration_line;
		text-decoration-thickness: $txt_text_decoration_thickness;
		text-decoration-style: $txt_text_decoration_style;
		text-underline-offset: $txt_text_underline_offset;
		text-underline-position: $txt_text_underline_position;
		text-indent: $txt_text_indent;
		text-overflow: $txt_text_overflow;
		text-shadow: $txt_text_shadow;
		text-transform: $txt_text_transform;
		white-space: $txt_white_space;
		word-break: $txt_word_break;
		word-spacing: $txt_word_spacing;
		color: $txt_color;
		filter: $txt_filter;
		mix-blend-mode: $txt_mix_blend_mode;
	}

	:where(&.is-hyphenated) {
		hyphens: auto;
	}

	:where(&.has-no-display-transform) {
		font-family: ch-ff("headline");
		text-transform: ch-tt("headline");
	}

	:where(&.has-display-transform) {
		font-family: ch-ff("display");
		text-transform: ch-tt("display");
	}

	:where(&.is-bold),
	:where(&) :where(b, strong) {
		font-weight: $txt_bold_font_weight;
	}

	:where(&.is-italic) {
		font-style: italic;
	}

	:where(a, button, .is-interactive) :where(&) {
		transition-duration: $txt_transition_duration;
		transition-property: $txt_transition_property;
		transition-timing-function: $txt_transition_timing_function;
	}

	:where(&.is-clamped) {
		-webkit-line-clamp: $txt_line_clamp;
	}

	:where(html:not([dir="rtl"])) :where(&.has-ess-indent-fix) {
		text-indent: ch-ti("#{$variant}_fix_ess");
	}
	:where(html:not([dir="rtl"])) :where(&.has-edge-indent-fix) {
		text-indent: ch-ti("#{$variant}_fix_edge");
	}
	:where(html:not([dir="rtl"])) :where(&.has-curve-indent-fix) {
		text-indent: ch-ti("#{$variant}_fix_curve");
	}

	@include ch-break-hover() {
		:where(a, button, .is-interactive):where(:hover) :where(&) {
			color: $txt_color_hover;
			text-decoration-color: $txt_text_decoration_color_hover;
		}
	}

	:where(a, button, .is-interactive):where(:active) :where(&) {
		color: $txt_color_hover;
		text-decoration-color: $txt_text_decoration_color_hover;
	}

	:where(a, button, .is-interactive):where(.is-active) :where(&) {
		color: $txt_color_active;
		text-decoration-color: $txt_text_decoration_color_active;
	}
}

// Inline Text Link
// =============================================================================

@mixin ch-inline-link() {
	$ch_inline_link_margin_x_start: var(
		--ch-inline-link-margin-x-start,
		var(--ch-inline-link-margin-x, 0px)
	);
	$ch_inline_link_margin_x_end: var(
		--ch-inline-link-margin-x-end,
		var(--ch-inline-link-margin-x, 0px)
	);
	$ch_inline_link_margin_y_start: var(
		--ch-inline-link-margin-y-start,
		var(--ch-inline-link-margin-y, 0px)
	);
	$ch_inline_link_margin_y_end: var(
		--ch-inline-link-margin-y-end,
		var(--ch-inline-link-margin-y, 0px)
	);
	$ch_inline_link_border_width: var(--ch-inline-link-border-width, 0px);
	$ch_inline_link_border_style: var(--ch-inline-link-border-style, solid);
	$ch_inline_link_border_radius: var(--ch-inline-link-border-radius, 0px);
	$ch_inline_link_padding_x_start: var(
		--ch-inline-link-padding-x-start,
		var(--ch-inline-link-padding-x, 0px)
	);
	$ch_inline_link_padding_x_end: var(
		--ch-inline-link-padding-x-end,
		var(--ch-inline-link-padding-x, 0px)
	);
	$ch_inline_link_padding_y_start: var(
		--ch-inline-link-padding-y-start,
		var(--ch-inline-link-padding-y, 0px)
	);
	$ch_inline_link_padding_y_end: var(
		--ch-inline-link-padding-y-end,
		var(--ch-inline-link-padding-y, 0px)
	);
	$ch_inline_link_gap: var(--ch-inline-link-gap, #{ch-d("inline_link_gap")});
	$ch_inline_link_font_weight_normal: var(
		--ch-inline-link-font-weight,
		inherit
	);
	$ch_inline_link_font_weight_bold: var(
		--ch-inline-link-font-weight-bold,
		inherit
	);
	$ch_inline_link_text_decoration_line: var(
		--ch-inline-link-text-decoration-line,
		underline
	);
	$ch_inline_link_text_decoration_thickness: var(
		--ch-inline-link-text-decoration-thickness,
		#{ch-d("inline_link_text_decoration_thickness")}
	);
	$ch_inline_link_text_decoration_style: var(
		--ch-inline-link-text-decoration-style,
		solid
	);
	$ch_inline_link_text_underline_offset: var(
		--ch-inline-link-text-underline-offset,
		#{ch-d("inline_link_text_underline_offset")}
	);
	$ch_inline_link_text_underline_position: var(
		--ch-inline-link-text-underline-position,
		auto
	);
	$ch_inline_link_text_transition_duration: var(
		--ch-inline-link-text-transition-duration,
		#{ch-dur("zero")}
	);
	$ch_inline_link_text_transition_property: var(
		--ch-inline-link-text-transition-property,
		color,
		background-color,
		border-color,
		text-decoration-color
	);
	$ch_inline_link_text_transition_timing_function: var(
		--ch-inline-link-text-transition-duration,
		#{ch-ease("std")}
	);

	$ch_inline_link_icon_stroke_width_normal: var(
		--ch-inline-link-icon-stroke-width,
		#{$ch_inline_link_text_decoration_thickness}
	);
	$ch_inline_link_icon_stroke_width_bold: var(
		--ch-inline-link-icon-stroke-width,
		#{ch-d("inline_link_icon_stroke_width")}
	);

	$ch_inline_link_color_default: var(
		--ch-inline-link-color,
		#{ch-c("primary_accent")}
	);
	$ch_inline_link_bg_color_default: var(
		--ch-inline-link-bg-color,
		transparent
	);
	$ch_inline_link_border_color_default: var(
		--ch-inline-link-border-color,
		currentColor
	);
	$ch_inline_link_text_decoration_default: var(
		--ch-inline-link-text-decoration-color,
		currentColor
	);

	$ch_inline_link_color_hover: var(
		--ch-inline-link-color-hover,
		#{$ch_inline_link_color_default}
	);
	$ch_inline_link_bg_color_hover: var(
		--ch-inline-link-bg-color-hover,
		transparent
	);
	$ch_inline_link_border_color_hover: var(
		--ch-inline-link-border-color-hover,
		currentColor
	);
	$ch_inline_link_text_decoration_hover: var(
		--ch-inline-link-text-decoration-color-hover,
		transparent
	);

	:where(&) {
		--ch-icon-stroke-width: #{$ch_inline_link_icon_stroke_width_normal};
		--ch-icon-transition-duration: inherit;
		--ch-icon-transition-property: none;
		--ch-icon-transition-timing-function: inherit;

		position: relative;
		display: inline;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: baseline;
		gap: $ch_inline_link_gap;
		margin-inline: $ch_inline_link_margin_x_start
			$ch_inline_link_margin_x_end;
		margin-block: $ch_inline_link_margin_y_start
			$ch_inline_link_margin_y_end;
		border-width: $ch_inline_link_border_width;
		border-style: $ch_inline_link_border_style;
		border-radius: $ch_inline_link_border_radius;
		padding-inline: $ch_inline_link_padding_x_start
			$ch_inline_link_padding_x_end;
		padding-block: $ch_inline_link_padding_y_start
			$ch_inline_link_padding_y_end;
		font-weight: $ch_inline_link_font_weight_normal;
		text-decoration-line: $ch_inline_link_text_decoration_line;
		text-decoration-thickness: $ch_inline_link_text_decoration_thickness;
		text-decoration-style: $ch_inline_link_text_decoration_style;
		text-underline-offset: $ch_inline_link_text_underline_offset;
		text-underline-position: $ch_inline_link_text_underline_position;
		transition-duration: $ch_inline_link_text_transition_duration;
		transition-property: $ch_inline_link_text_transition_property;
		transition-timing-function: $ch_inline_link_text_transition_timing_function;

		color: $ch_inline_link_color_default;
		border-color: $ch_inline_link_border_color_default;
		background-color: $ch_inline_link_bg_color_default;
		text-decoration-color: $ch_inline_link_text_decoration_default;
	}

	:where(&.is-bold, .is-bold &),
	:where(&) :where(b, strong),
	:where(b, strong) :where(&) {
		font-weight: $ch_inline_link_font_weight_bold;
	}

	:where(*) {
		color: currentColor !important;
	}

	@include ch-break-hover {
		:where(&:hover) {
			color: $ch_inline_link_color_hover;
			border-color: $ch_inline_link_border_color_hover;
			background-color: $ch_inline_link_bg_color_hover;
			text-decoration-color: $ch_inline_link_text_decoration_hover;
			text-decoration: underline !important;
		}
	}

	:where(&:active) {
		color: $ch_inline_link_color_hover;
		border-color: $ch_inline_link_border_color_hover;
		background-color: $ch_inline_link_bg_color_hover;
		text-decoration-color: $ch_inline_link_text_decoration_hover;
	}
}

// User Select
// =============================================================================

@mixin ch-user-select($value: none) {
	-webkit-user-select: $value;
	-moz-user-select: $value;
	-ms-user-select: $value;
	user-select: $value;
}

// Masks
// =============================================================================

@mixin ch-mask-clip($value) {
	-webkit-mask-clip: $value;
	mask-clip: $value;
}

@mixin ch-mask-image($value) {
	-webkit-mask-image: $value;
	mask-image: $value;
}

@mixin ch-mask-origin($value) {
	-webkit-mask-origin: $value;
	mask-origin: $value;
}

@mixin ch-mask-position($value) {
	-webkit-mask-position: $value;
	mask-position: $value;
}

@mixin ch-mask-repeat($value) {
	-webkit-mask-repeat: $value;
	mask-repeat: $value;
}

@mixin ch-mask-size($value) {
	-webkit-mask-size: $value;
	mask-size: $value;
}

@mixin ch-mask-type($value) {
	-webkit-mask-type: $value;
	mask-type: $value;
}

// <ScrollerX> Styled Scrollbar
// =============================================================================

@mixin ch-scroller-x-styled-scrollbar(
	$size,
	$inset_x,
	$inset_track_y,
	$track_color,
	$thumb_color,
	$thumb_radius,
	$inset_color
) {
	// Scrollbar
	// ---------

	&::-webkit-scrollbar {
		height: $size;
		border: 0px;
		background: transparent;
		scrollbar-width: thin;
		scrollbar-color: $thumb_color $track_color;
	}

	&::-webkit-scrollbar:active {
		height: $size;
		border: 0px;
		background: transparent;
		scrollbar-width: thin;
		scrollbar-color: $thumb_color $track_color;
	}

	// Track
	// -----

	&::-webkit-scrollbar-track {
		border-inline: $inset_x solid transparent;
		border-block: $inset_track_y solid transparent;
		background: $track_color;
		background-clip: content-box;
	}

	&::-webkit-scrollbar-track:active {
		border-inline: $inset_x solid transparent;
		border-block: $inset_track_y solid transparent;
		background: $track_color;
		background-clip: content-box;
	}

	// Thumb
	// -----

	&::-webkit-scrollbar-thumb {
		border-inline: $inset_x solid $inset_color;
		border-block: 0px solid transparent;
		border-radius: $thumb_radius;
		background: $thumb_color;
		background-clip: content-box;
	}

	&::-webkit-scrollbar-thumb:active {
		border-inline: $inset_x solid $inset_color;
		border-block: 0px solid transparent;
		border-radius: $thumb_radius;
		background: $thumb_color;
		background-clip: content-box;
	}
}
